.statusElement .value.processing {
  color: var(--color-logo-0);
}
.statusElement .value.queued {
  color: var(--color-logo-0);
}
.statusElement .value.rejected {
  color: var(--color-logo-1);
}
.statusElement .value {
  font-family: monospace;
  font-size: 1.05em; 
}

