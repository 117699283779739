.Promo {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: var(--max-width); 
}

.Promo .container {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0; 
  position: relative;
}

.Promo .splash {
  display: flex;
  margin-top: -300px;
}

.Promo .splash .label {
  font-size: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  width: 500px;
}

.Promo .splash .label .icons {
  display: flex;
  width: 500px;
  justify-content: flex-start;
  margin-left: -6px;
}
.Promo .splash .label .icons svg {
  width: 60px;
  height: 60px;
  cursor: pointer;
  color: var(--color-logo-0);
}

.Promo .splash h1 {
  padding: 0;
  margin: 0;
}

.Promo .splash img {
  width: 400px;
  height: 400px;
}

.Promo .tutorials {
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.Promo .tutorials .tutorialContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-logo-0);
}

.Promo .tutorials .tutorialContainer .videoContainer img {
  height: 100px;
}

.Promo .tutorials .tutorialContainer .videoTitle {
  font-size: 0.6em;
  color: var(--color-text);
  display: flex;
  justify-content: center;
  padding: 5px;
}

.Promo .promocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Promo .promosection {
  margin-bottom: 50px;
  max-width: 900px;
}

.Promo .promosection .top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Promo .promosection .top h1 {
  padding: 0;
  margin: 0;
}
.Promo .promosection .top svg {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  color: var(--color-logo-0);
}
.Promo .promosection p {
  line-height: 1.5em;
}

.Promo .bloglink {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.Promo .bloglink a {
  margin-left: 5px;
}



@media (max-width: 1000px) {
  .Promo .splash img {
    width: 250px;
    height: 250px;
  }
  .Promo .splash .label {
    font-size: 1.8em;
    width: 300px;
  }
  .Promo .splash .label .icons {
    display: flex;
    width: 300px;
    margin-left: -6px;
  }
  .Promo .splash .label .icons svg {
    width: 35px;
    height: 35px;
    cursor: pointer;
    color: var(--color-logo-0);
  }
  .Promo .promosection {
    margin-bottom: 50px;
    max-width: 550px;
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .Promo .splash img {
    width: 140px;
    height: 140px;
  }
  .Promo .splash .label {
    font-size: 1.2em;
    width: 180px;
  }
  .Promo .splash .label .icons {
    display: flex;
    width: 180px;
    margin-left: -6px;
  }
  .Promo .splash .label .icons svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: var(--color-logo-0);
  }
  .Promo .promosection {
    margin-bottom: 50px;
    max-width: 300px;
    font-size: 0.8em;
  }
  .Promo .bloglink {
    font-size: 0.8em;
  }
  .Promo .tutorials {
    bottom: 120px;
  }
  .Promo .tutorials .tutorialContainer .videoContainer img {
    height: 80px;
  }
  .Promo .tutorials .tutorialContainer .videoTitle {
    font-size: 6px;
  }
}
