.Idz {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: var(--max-width); 
}

.Idz .container {
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.Idz .quipu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: var(--idz-box-width);
}
.Idz .quipuIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.Idz .quipuIcons .quipuIcon {
  width: 100px;
  z-index: 2;
}
.Idz .quipuIcons .idzIcon {
  width: 60px;
  z-index: 1;
  margin-right: -30px;
}
.Idz .quipuLinks {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.Idz .quipuLinks a {
  font-size: 1.2em;
  padding-top: 3px;
  padding-bottom: 3px;
  color: var(--color-text);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Idz .readlink {
  margin-top: 15px;
  color: var(--color-text);
  font-size: 0.8em;
  line-height: 1.5em;
  text-align: center;
}
.Idz .readlink a {
  color: var(--color-text);
}
.Idz .readlink .nb {
  color: var(--color-logo-1);
}

.Idz .myHeading {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: var(--color-text);
}

.Idz .my {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: var(--idz-box-width);
}

.Idz .my .element {
  display: flex;
  justify-content: space-between;
}
.Idz .my .element div {
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.Idz .my .element div.label {
  border-right: 1px solid var(--color-logo-0);
  justify-content: flex-start;
  color: white;
  background-color: var(--color-logo-0);
  font-weight: bold;
}
.Idz .my .element:first-of-type div.label {
  border-top: 1px solid var(--color-logo-0);
  border-radius: 5px 0px 0px 0px;
}
.Idz .my .element:last-of-type div.label {
  border-radius: 0px 0px 0px 5px;
}
.Idz .my .element div.value {
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-logo-0);
  border-right: 1px solid var(--color-logo-0);
}
.Idz .my .element:first-of-type div.value {
  border-top: 1px solid var(--color-logo-0);
  border-radius: 0px 5px 0px 0px;
}
.Idz .my .element:last-of-type div.value {
  border-radius: 0px 0px 5px 0px;
}

.Idz .chart {
  border: 1px solid var(--color-logo-0);
  border-radius: 5px; 
  padding: 0;
  margin-top: 10px;
  width: var(--idz-box-width);
}
.Idz .chart canvas {
  padding: 0;
  margin: -5px;
}

.Idz .xidzHeading {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  color: var(--color-text);
}

.Idz .xidzWrapper {
  position: relative;
}
.Idz .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 100;
}
.Idz .loader img {
  width: 50px;
  animation: spin ease 2s infinite;
}

.Idz .xidz {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: var(--idz-box-width);
}
.Idz .xidz.loading {
  opacity: 0.5;
}

.Idz .xidz .element {
  display: flex;
  justify-content: space-between;
}
.Idz .xidz .element div {
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.Idz .xidz .element div.label {
  border-right: 1px solid var(--color-logo-xidz);
  justify-content: flex-start;
  color: white;
  background-color: var(--color-logo-xidz);
  font-weight: bold;
}
.Idz .xidz .element:first-of-type div.label {
  border-top: 1px solid var(--color-logo-xidz);
  border-radius: 5px 0px 0px 0px;
}
.Idz .xidz .element:last-of-type div.label {
  border-radius: 0px 0px 0px 5px;
}
.Idz .xidz .element div.value {
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-logo-xidz);
  border-right: 1px solid var(--color-logo-xidz);
}
.Idz .xidz .element:first-of-type div.value {
  border-top: 1px solid var(--color-logo-xidz);
  border-radius: 0px 5px 0px 0px;
}
.Idz .xidz .element:last-of-type div.value {
  border-radius: 0px 0px 5px 0px;
}

.Idz .xidz .element div.value.cost {
  color: var(--color-logo-1);
}
.Idz .xidz .element div.value.withmax {
  justify-content: space-between;
}
.Idz .xidz .element div.value .max {
  font-size: 0.8em;
  color: var(--color-logo-xidz);
  cursor: pointer;
}

.Idz .stakeButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: var(--idz-box-width);
}

.Idz .stakeButtons button {
  border: 1px solid var(--color-logo-xidz);
  background-color: var(--color-logo-xidz);
  color: white;
}
.Idz .stakeButtons button.disabled {
  opacity: 0.5;
}

.Idz .stakeValue {
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-logo-xidz);
  padding-top: 0;
  padding-bottom: 0;
}
.Idz .stakeValue input {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100px;
  height: 25px;
  text-align: right;
  font-size: 1em;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
