:root {
  --color-background: #ffffff;
  --color-background-hover: #e3e3e3;
  --color-border: #bbb;
  --color-text: #4d4d4d;
  --color-logo-0: #ff834f;
  --color-logo-0-rgb: rgb(255, 131, 79);
  --color-logo-0-rgb-opacity: rgb(255, 131, 79, 0.1);
  --color-logo-0-rgb-opacity-2: rgb(255, 131, 79, 0.8);
  --color-logo-1: #ff4949;
  --color-logo-1-rgb: rgb(255, 73, 73);
  --color-logo-1-rgb-opacity: rgb(255, 73, 73, 0.1);
  --color-logo-2: #ffae78;
  --color-logo-2-rgb: rgb(255, 174, 120);
  --color-logo-2-rgb-opacity: rgb(255, 174, 120, 0.1);
  --color-logo-xidz: #2573F0;
  --color-logo-xidz-rgb: rgb(37, 115, 240); 
  --color-logo-xidz-rgb-opacity: rgb(37, 115, 240, 0.1); 
  --color-logo-xidz-light: #4185F2;
  --color-verified: #5DAB55;
  --max-width: 1000px;
  --idz-box-width: 300px;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5; 
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px)
  }
  50% {
    transform: translateY(-10px)
  }
  100% {
    transform: translateY(0px)
  }
}

@keyframes pulseandbounce {
  0% {
    opacity: 0.5;
    transform: translateY(0px)
  }
  50% {
    opacity: 1;
    transform: translateY(-10px)
  }
  100% {
    opacity: 0.5; 
    transform: translateY(0px)
  }
}

@keyframes roll {
  0% {
    transform: translateX(20px) rotate(30deg)
  }
  50% {
    transform: translateX(-20px) rotate(-30deg)
  }
  100% {
    transform: translateX(20px) rotate(30deg)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: white;
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

#App {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--color-background);
  justify-content: center;
}

.spacer {
  flex: auto;
}

a {
  color: var(--color-text);
}

button {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  background-color: white; 
  color: var(--color-text); 
}
button.submit {
  border: 1px solid var(--color-logo-0);
  background-color: white; 
  color: var(--color-logo-0); 
}
button.cancel {
  border: 1px solid var(--color-border);
  background-color: white; 
  color: var(--color-text); 
}
button:disabled {
  opacity: 0.5;
}
