.resourceCollectorPhone.status {
  justify-content: center;
  align-items: center;
}

.resourceCollectorPhone .inner {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}

.resourceCollectorPhone .form {
  padding-bottom: 0px;
}
.resourceCollectorPhone .form input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-border);
  height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 2px;
}
.resourceCollectorPhone .form input:focus {
  outline: none;
  box-shadow: none;
}
.resourceCollectorPhone .form input:invalid {
  outline: none;
  box-shadow: none;
}
.resourceCollectorPhone .form input.error,
.resourceCollectorPhone .form input.error::placeholder {
  color: var(--color-logo-1);
}
