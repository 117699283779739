.resourceDetailsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  border: 1px solid var(--color-logo-0);
  border-radius: 5px;
}

.resourceDetailsContainer .top {
  display: flex;
  flex-direction: column;
  margin-bottom: 0!important;
}

.resourceDetailsContainer .top .header {
  height: 40px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-logo-0);
}
.resourceDetailsContainer .top .header {
  background-color: var(--color-logo-0);
  color: white;
}
.resourceDetailsContainer .top .header h3 {
  padding: 0;
  margin: 0;
  flex: auto;
  text-align: center;
}
.resourceDetailsContainer .top p {
  margin: 10px 0px;
  padding: 0px 10px;
  margin-bottom: 10px;
  font-size: 0.8em;
  font-style: italic;
}

.resourceDetailsContainer .main {
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 10px;
  padding-top: 0px;
}

.resourceDetailsContainer .status {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.resourceDetailsContainer .statusElement {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  margin-top: 3px;
}
.resourceDetailsContainer .statusElement label {
  flex: auto;
  font-style: italic;
}
.resourceDetailsContainer .statusElement .value {
  display: flex;
}
.resourceDetailsContainer .statusElement .value.expired {
  color: red;
}

.resourceDetailsContainer .buttons {
  display: flex;
  padding: 20px 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}

.resourceDetailsContainer .buttons button {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
}
.resourceDetailsContainer .buttons button.submit {
  border: 1px solid var(--color-logo-0);
  color: white; 
  background-color: var(--color-logo-0); 
}
.resourceDetailsContainer .buttons button.cancel {
  border: 1px solid var(--color-border);
  background-color: white; 
  color: var(--color-text); 
}
.resourceDetailsContainer .buttons button:disabled {
  opacity: 0.5;
}

.disclaimer {
  margin-top: 5px;
  font-size: 0.6em;
  top: 5px;
  left: 18px;
  color: var(--color-text);
}
