.confirmAlert {
  border: 1px solid var(--color-logo-0);
  border-radius: 10px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.confirmAlert .title {
  padding: 10px 0px;
  border-radius: 10px 10px 0px 0px;
  font-size: 1.2em;
  font-weight: bold;
  width: 100%;
  background-color: var(--color-logo-0);
  color: white;
}
.confirmAlert .title div {
  padding-left: 10px;
}
.confirmAlert .message {
  padding: 10px;
  font-size: 0.9em;
  background-color: white;
}
.confirmAlert .buttons {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  flex: auto;
  width: 300px;
  justify-content: space-between; 
  background-color: white;
}
.confirmAlert .buttons .submit {
  background-color: var(--color-logo-0);
  color: white;
}
