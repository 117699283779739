.KYCToggle {
}
.KYCToggle .react-toggle-track {
  width: 40px;
  height: 18px;
  background-color: var(--color-border);
}
.KYCToggle .react-toggle-thumb {
  width: 16px;
  height: 16px;
}
.KYCToggle.react-toggle--checked .react-toggle-track {
  background-color: var(--color-logo-0);
}
.KYCToggle.react-toggle.react-toggle--checked:hover .react-toggle-track {
  background-color: var(--color-logo-0);
}
.KYCToggle.react-toggle:hover .react-toggle-track {
  background-color: var(--color-border);
}
.KYCToggle.react-toggle--checked .react-toggle-thumb {
  left: 22px;
  border-color: var(--color-logo-0);
}
.KYCToggle.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: none; 
  -moz-box-shadow: none; 
  box-shadow: none; 
}

.KYCToggle.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: none; 
  -moz-box-shadow: none; 
  box-shadow: none; 
}

.platforms {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.platforms .platformItem {
  display: flex;
  padding: 5px;
  cursor: pointer;
  position: relative;
}
.platforms .platformItem.disabled {
  opacity: 0.5;
}
.platforms .platformItem.disabled:hover {
  opacity: 1;
}
.platforms .platformItem .mark {
  position: absolute;
  right: 0;
  bottom: 0;
}
.platforms .platformItem.disabled .mark {
  color: var(--color-logo-1);
}
.platforms .platformItem .mark {
  color: var(--color-verified); 
}

.platforms .platformItem img {
  height: 40px;
  border-radius: 50%;
}
.platforms .platformItem.nextPlatform svg {
  color: var(--color-logo-0);
  margin-right: -8px;
}
