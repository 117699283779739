.Profile {
  flex: auto;
  display: flex;
  flex-direction: column;
  max-width: var(--max-width); 
}

.Profile .container {
  flex: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.Profile .container .card {
  width: 450px;
  border: 1px solid var(--color-logo-0);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .Profile .container .card {
    width: 300px;
  }
  .Profile .container .card .top .textual .description {
    font-size: 0.7em!important;
  }
}

.Profile .container .card .top {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}
.Profile .container .card .top .avatar {
  width: 100px;
  height: 100px;
  border: 1px solid var(--color-logo-0);
  border-radius: 50px;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.Profile .container .card .top .avatar.edit {
  cursor: pointer;
  opacity: 0.6;
}
.Profile .container .card .top .avatar svg {
  width: 30px;
  height: 30px;
  color: var(--color-logo-0);
  opacity: 0.7;
}
.Profile .container .card .top .textual {
  flex: auto;
  padding: 5px;
  color: var(--color-text);
}
.Profile .container .card .top .textual input.name {
  width: 70%;
}
.Profile .container .card .top .textual .description {
  margin-top: 5px;
  font-size: 0.9em;
  font-style: italic;
}
.Profile .container .card .top .textual textarea.description {
  width: 70%;
  height: 70%;
}

.Profile .container .sectionLabel {
  font-size: 0.8em;
  color: var(--color-text);
  margin-bottom: 5px;
}
.Profile .container .proofList {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  max-width: 200px;
  flex-wrap: wrap;
  gap: 5px;
}
.Profile .container .proofList .proofListItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid var(--color-logo-0);
  cursor: pointer;
}
.Profile .container .proofList .proofListItem svg {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.Profile .container .proofList .proofListItem.new svg {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
}
.Profile .container .proofList .proofListItem.email svg {
  width: 35px;
  height: 35px;
  margin-bottom: 2.5px;
}
.Profile .container .proofList .proofListItem.twitter svg {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.Profile .container .proofList .proofListItem .proofLabel {
  font-size: 0.4em;
}
/** UNREGISTERED **/
.Profile .container .proofList .proofListItem.unregistered {
  border-color: var(--color-border);
}
.Profile .container .proofList .proofListItem.unregistered .proofLabel {
  color: var(--color-border); 
}
.Profile .container .proofList .proofListItem.unregistered svg path {
  fill: var(--color-border);
}
/** REGISTERED **/
.Profile .container .proofList .proofListItem.registered {
  border-color: var(--color-logo-0);
  opacity: 0.5;
}
.Profile .container .proofList .proofListItem.registered .proofLabel {
  color: var(--color-logo-0);
}
.Profile .container .proofList .proofListItem.registered svg path {
  fill: var(--color-logo-0);
}
/** VERIFIED **/
.Profile .container .proofList .proofListItem.verified {
  border-color: var(--color-logo-0);
}
.Profile .container .proofList .proofListItem.verified .proofLabel {
  color: var(--color-logo-0);
}
.Profile .container .proofList .proofListItem.verified svg path {
  fill: var(--color-logo-0);
}
/** EXPIRED **/
.Profile .container .proofList .proofListItem.expired {
  border-color: var(--color-logo-1);
}
.Profile .container .proofList .proofListItem.expired .proofLabel {
  color: var(--color-logo-1);
}
.Profile .container .proofList .proofListItem.expired svg path {
  fill: var(--color-logo-1);
}
/** SELECTED */
.Profile .container .proofList .proofListItem.selected {
  border-color: var(--color-logo-0);
  background-color: var(--color-logo-0);
}
.Profile .container .proofList .proofListItem.selected .proofLabel {
  color: white;
}
.Profile .container .proofList .proofListItem.selected svg path {
  fill: white;
}

.Profile .tezosnames {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-size: 0.8em;
}
.Profile .tezosnames a {
  text-decoration: none;
}
.Profile .tezosnames .domain {
  margin: 5px;
}

.Profile .container .proofDetails.hidden {
  display: none;
}
.Profile .container .proofDetails {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.Profile .qrcode {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.Profile .container .card .avatarEdit {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}
.Profile .container .card .avatarEdit svg {
  color: var(--color-logo-0);
  cursor: pointer;
}
.Profile .container .card .avatarEdit button {
  padding: 5px;
  margin-top: 4px;
  font-size: 0.65em;
}

.Profile .container .card .cardFooter {
  position: absolute;
  left: -200px;
  bottom: -240px;
}
.Profile .container .card .cardFooter img {
  width: 500px;
  opacity: 0.2;
}

.Profile .container .card .noWallet {
  height: 500px;
  display: flex;
  justify-content: center;
}

.Profile .icards {
  position: relative;
  width: 300px;
  height: 160px;
  overflow: scroll;
}
.Profile .icards .icard {
  position: absolute;
  top: 0;
  left: 8%;
  cursor: pointer;
}
.Profile .icards .icard:hover {
  transform: translateY(-3px);
  transition: all 150ms ease-in-out;
}

.Profile .worldcoinicon {
  transform: scale(1.3);
}
