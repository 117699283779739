.resourceCollectorWorldID.status {
  justify-content: center;
  align-items: center;
}

.resourceCollectorWorldID .inner {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resourceCollectorWorldID.status h3 {
  font-size: 0.9em;
  padding: 5px;
  font-weight: normal;
}

.resourceCollectorWorldID.status a {
  font-size: 0.8em;
  margin-top: 10px;
  font-weight: normal;
}

.resourceCollectorWorldID.buttons .statusElement {
  align-items: center;
}

.resourceCollectorWorldID svg {
  fill: var(--color-logo-0);
}
