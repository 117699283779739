.Error {
  border: 1px solid blue;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Error h1 {
  color: red;
  font-size: 3em;
}

.Error .emoji {
  margin-bottom: 20px;
  font-size: 50px;
}

.Error code {
  border: 1px solid #ccc;
  padding: 30px;
  margin-bottom: 40px;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
}

.Error .suggestion {
  font-style: italic;
}

.Error .suggestion .action {
  color: blue;
  cursor: pointer;
}
