.Apps {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: var(--max-width); 
}

.Apps .container {
  flex: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.Apps .app {
  display: flex;
  border: 1px solid var(--color-border);
  align-items: flex-start;
  cursor: pointer;
  flex-direction: column;
  width: 300px;
  margin-top: 20px;
}

.Apps .app .top {
  display: flex;
}

.Apps .app .icon {
  padding: 10px;
  display: flex;
}
.Apps .app .icon img {
  height: 50px;
  width: 50px;
}

.Apps .app .info {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0px;
}

.Apps .app .info .name {
  font-size: 1.5em;
}
.Apps .app .info .tagline {
  font-size: 0.9em;
  padding-top: 2px;
}

.Apps .app .bottom {
  word-wrap: wrap;
  padding: 10px 10px 10px 10px;
  font-size: 0.8em;
}
