.header {
  --height: 60px;
}

.header {
  height: var(--height);
  display: flex;
  padding: 10px;
  padding-bottom: 0px;
}

.header .logo {
  cursor: pointer;
}

.header .logo img {
  height: 50px;
  margin-right: 8px;
}
.header .logo .logoSeparator {
  height: 50px;
  width: 1.5px;
  background-color: var(--color-text);
}
.header .logo .logoLabels {
  margin-left: 8px;
  color: var(--color-text);
  font-family: 'Montserrat', sans-serif;
}
.header .logo .logoLabel {
  font-size: 2em;
  font-weight: 700;
}
.header .logo .logoLabelSmall {
  font-size: 0.8em;
  font-weight: 600;
}


.header .logo, .header .walletSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 250px;
}
.header .logo {
  justify-content: flex-start;
}
.header .walletSelector {
  justify-content: flex-end;
}

.header .menu {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .menu .menuItem {
  height: 30px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  cursor: pointer;
  border-bottom: 1px solid var(--color-logo-0);
}

.costIndicator {
}

.header .gettingStarted {
  background-color: var(--color-logo-0);
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.header .TezosWallet {
  border: 1px solid var(--color-logo-0);
}
.header .TezosWallet .spacer {
  border-left: 1px solid var(--color-logo-0);
}
.header .TezosWallet .TezosWalletMenu {
  border: 1px solid var(--color-logo-0);
}
.header .TezosWalletMenuCopyAddress .icon {
  font-size: 1.2em;
} 
.header .TezosWalletMenuCopyAddressInner {
  display: flex;
  flex: 1;
}
.header .TezosWalletMenuRemove .icon {
  font-size: 1.2em;
} 


@media (max-width: 800px) {
  .header .logo, .header .walletSelector {
    width: 130px;
  }
  .header .logo img {
    height: 40px;
    margin-right: 8px;
  }
  .header .logo .logoSeparator {
    height: 40px;
    width: 1.5px;
    background-color: var(--color-text);
  }
  .header .logo .logoLabels {
    margin-left: 8px;
    color: var(--color-text);
    font-family: 'Montserrat', sans-serif;
  }
  .header .logo .logoLabel {
    font-size: 1.5em;
    font-weight: 700;
  }
  .header .logo .logoLabelSmall {
    font-size: 0.6em;
    font-weight: 600;
  }
}

@media (max-width: 600px) {
  .header .menu .menuItem {
    display: none;
  }
}

@media (max-width: 500px) {
  .header .TezosWallet {
    border: none; 
  }
}
