.Processing {
  position: relative;
  display: flex;
  flex: auto;
  justify-content: center;
}

.Processing .processBox {
  padding-top: 10px;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
}

.Processing .processBox.error {
}

.Processing .processBox.info {
}

.Processing .processBox .processingLoader img {
  width: 50px;
  margin-bottom: 10px;
}
.Processing .processBox .processingLoader.info img {
  animation: spin ease 2s infinite;
}

.Processing .processBox .processingMessage {
  font-size: 0.9em;
  text-align: center;
}

.Processing .processBox .canClose {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}
