.resourceCollectorGov.status {
  justify-content: center;
  align-items: center;
}

.resourceCollectorGov .inner {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resourceCollectorGov.status h3 {
  font-size: 0.9em;
  padding: 5px;
  font-weight: normal;
}

.resourceCollectorGov.status a {
  font-size: 0.8em;
  margin-top: 10px;
  font-weight: normal;
}

.resourceCollectorGov.buttons .statusElement {
  align-items: center;
}
