.footer {
  height: 60px;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column-reverse;
}

@media (max-width: 800px) {
  .footer {
    flex-direction: row;
    height: 40px;
  }
}

.footer .spacer {
  flex: auto;
}

.footer .infoLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .infoLinks a {
  color: var(--color-text);
  text-decoration: none;
  padding: 5px;
  font-size: 0.8em;
}

.footer .resourceLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .resourceLinks a {
  color: var(--color-logo-0);
  padding: 5px;
}
