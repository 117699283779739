.resourceCollectorTwitter.status {
  justify-content: center;
  align-items: center;
}

.resourceCollectorTwitter .inner {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}

.resourceCollectorTwitter .form {
  padding-bottom: 0px;
}
.resourceCollectorTwitter .form input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-border);
  height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 2px;
}
.resourceCollectorTwitter .form input:focus {
  outline: none;
  box-shadow: none;
}
.resourceCollectorTwitter .form input:invalid {
  outline: none;
  box-shadow: none;
}
.resourceCollectorTwitter .form input.error,
.resourceCollectorTwitter .form input.error::placeholder {
  color: var(--color-logo-1);
}

.twittermessage {
  border: 1px solid var(--color-logo-0);
  border-radius: 5px;
  background-color: #ddd;
  font-family: monospace;
  font-size: 0.8em;
  line-height: 1.5em;
  padding: 10px;
}
