.Preparing {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: var(--max-width); 
}

.Preparing .content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Preparing .content .loader {
  width: 80px;
  padding: 10px;
  animation: pulseandbounce ease 2s infinite;
}
